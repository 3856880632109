<template>
  <div class="row mx-0 h-100">
    <div class="col-12 p-0 card_item h-100">
      <div class="bg_overlay"></div>
      <div class="row mx-0 h-100 justify-content-center above">
        <div class="col-md-5 p-0"  ref="theImage">
          <div class="price bold" v-if="project.fixed">{{defaultCurrency}}{{project.cost}} {{project.costType.description}}</div>
          <div class="price bold" v-else-if="selectedChildProject.fixed">{{defaultCurrency}}{{selectedChildProject.cost}} {{selectedChildProject.costType.description}}</div>
          <Image :imageName="project.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="img-fluid fill" />
          <!-- <img :src="url + '/static/uploads/images/' + project.imageUrl" class="img-fluid fill" /> -->
          <div class="bottom-left" v-if="project.section18ATAX">
            <Button color="black_op" size="xsmall" btnText="18A" icon2="arrow" class="white-text" width="100" alignment="justify-content-between">
              <template v-slot:iconStart>
                <ios-checkmark-circle-icon class="gold-text inline-icon"/>
              </template>
            </Button>
          </div>
        </div>
        <div class="col-md-7 pt">
          <div class="row mx-0">
            <div class="col-12 p-0 title mb-4 text-center text-md-start">
              {{project.name}}
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-6 pe-1" v-if="childProjects.length > 0">
              <SelectProjectDisplay
                v-model="selectedChildProject"
                id="childproject"
                :options="childProjects"
                placeholder="Select Project"
                displayValue="description"
                color="gold"
              />
            </div>
            <div class="col-6" :class="{'col-12': childProjects.length === 0, 'ps-1': childProjects.length > 0}">
              <SelectAll
                v-model="donationType"
                id="project"
                :options="project.projectDonationTypeMaps"
                placeholder="Donation Type"
                displayValue="donationType"
                subDisplayValue="description"
                color="gold"
                :autoSelect="true"
              />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-5">
              <NumberField color="goldgold" v-model="donation.quantity" id="quantity" @change="setAmount" v-if="project.fixed" />
              <NumberField color="goldgold" v-model="donation.quantity" id="quantity" @change="setAmount" v-else-if="selectedChildProject.fixed" />
              <CurrencyField type="text" placeholder="0.00" id="amount" color="goldgold" v-model="donation.amount" :currencySymbol="defaultCurrency" v-else/>
            </div>
            <div class="col-7 ps-0">
              <Button color="red" width="100" btnText="Added" icon="arrow" class="mb-1" :disabled=true v-if="isAdded" />
              <Button color="red" width="100" btnText="Add to Cart" icon="arrow" @buttonClicked="addDonationToCart" class="mb-1" v-else :disabled="donation.amount < 0.01 || !donationType" />
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-auto view">
              <div class="row m-0 cursor align-items-center" @click="goToProject">
                <div class="col-auto p-0">View Project</div>
                <div class="col p-0">
                  <IconArrowForward color="gold" size="size16" />
                </div>
              </div>
            </div>
            <div class="col-auto view">
              <span class="cursor" @click="goToProject">More options</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProjectPopup :project="project" v-if="isMore" @close="hideMore" />
    <transition name="comeIn">
      <AddCartPopup v-if="isAdded" @close="isAdded = false"/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    NumberField: defineAsyncComponent(() => import('@/components/NumberField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    SelectProjectDisplay: defineAsyncComponent(() => import('@/components/SelectProjectDisplay.vue')),
    ProjectPopup: defineAsyncComponent(() => import('@/views/ProjectPopup.vue')),
    AddCartPopup: defineAsyncComponent(() => import('@/views/AddCartPopup.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    'ios-checkmark-circle-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark-circle.vue'))
  },
  name: 'AppealCard',
  props: ['project'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      selectedChildProject: {},
      donation: {
        quantity: 1,
        amount: 0.00,
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: [],
        plaqueDescription: ''
      },
      donationType: '',
      isAdded: false,
      isMore: false,
      url: process.env.VUE_APP_URL_BASE,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
    this.donation.projectId = this.project.projectID
    this.donation.currency = this.defaultCurrency
    this.setAmount()
  },
  watch: {
    selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.donation.projectId = this.selectedChildProject.adminProjectId
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects'
    ]),
    date () {
      const date = DateTime.fromMillis(this.project.lastModified)
      return date.toLocaleString(DateTime.DATE_FULL)
    },
    childProjects () {
      const arr = this.adminProjects.filter(project => {
        if (project.parentProjectID !== this.project.projectID) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    }
  },
  methods: {
    ...mapActions([
      'addToCart',
      'clearCart'
    ]),
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    },
    goToProject () {
      window.scroll(0, 0)
      this.$router.push('/project/' + this.project.id)
    },
    setAmount () {
      if (this.project.fixed) {
        this.donation.amount = this.donation.quantity * this.project.cost
      }
    },
    showMore () {
      this.isMore = true
    },
    hideMore () {
      this.isMore = false
    },
    goDonate () {
      this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    addDonationToCart () {
      this.project.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType !== this.donationType) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: this.donationType, amount: this.donation.amount })
        }
      })
      this.addToCart(this.donation)
      this.isAdded = true
      this.donation = {
        quantity: 1,
        amount: 0.00,
        currency: this.defaultCurrency,
        projectId: this.project.projectID,
        donationTypes: [],
        plaqueDescription: ''
      }
      this.selectedProject = {}
    }
  }
}
</script>

<style scoped>
.fill {
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 22px;
  border-bottom-right-radius: 0;
}
.card_item {
  background-color: transparent;
  background-image: linear-gradient(rgb(13, 53, 28) 0%, rgb(26, 104, 54) 100%);
  position: relative;
  border-radius: 22px;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 13%);
  min-height: 291px;
}
.card_item:hover {
  background-color: #16311B;
  box-shadow: 7px 7px 25px 0px rgb(0 0 0 / 33%);
}

.bg_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url(../../../assets/images/bg.png);
  background-size: 22% auto;
  opacity: 0.07;
  border-radius: 22px;
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.above {
  z-index: 2;
  position: relative;
}
.title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.38rem;
  font-weight: 600;
  color: #fff;
}
.view {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--gold-color);
  text-transform: uppercase;
}
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--green-color-dark);
}
.price {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: var(--green-color-dark);
  padding: 4px 11px;
  color: var(--gold-color);
  border-radius: 37px;
}
.bottom-left {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
.pt {
  padding-left: 40px;
  padding-right: 50px;
  padding-top: 44px;
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .fill {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card_item {
    background-color: #fff;
    border-radius: 22px;
    box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
  }
}

</style>
